<template>
   <nav class="navbar navbar-light py-2" fixed="top">
   <div class="container-fluid">
    <a class="navbar-brand"></a>
    <!-- <b-nav-item href="#">MyProHelper</b-nav-item> -->
    <b-nav-form class="d-flex">
      <b-navbar-nav>
      <button class="btn btn-Home" type="button" @click="Home">Home</button>
      <button class="btn btn-Features" type="button" @click="Features">Features</button>
      <button class="btn btn-MobileDevices" type="button" @click="MobileDevices">Mobile Devices</button>
      <button class="btn btn-Pricing" type="button" @click="Pricing">Pricing</button>
      <button class="btn btn-Support" type="button" @click="Support">Support</button>
      <button class="btn btn-ContactUs" type="button" @click="ContactUs">Contact Us</button>
      <button class="btn btn-AboutUs" type="button" @click="AboutUs">About Us</button>
      <button class="btn btn-Terms" type="button" @click="Terms">Terms and Conditions</button>
      <button class="btn btn-Privacy" type="button" @click="Privacy">Privacy</button>
      <button class="btn btn-SignUp" type="button" @click="SignUp">Sign-Up for MyProHelper</button>
      <!-- <b-nav-item-dropdown text="About Us" right>
          <b-dropdown-item href="#">Our Company</b-dropdown-item>
          <b-dropdown-item href="#">Our Management Team</b-dropdown-item>
          <b-dropdown-item href="#">Company Values</b-dropdown-item>
          <b-dropdown-item href="#">Terms and Conditions</b-dropdown-item>
          <b-dropdown-item href="#">Privacy Policy</b-dropdown-item>
          <b-dropdown-item href="#">Careers</b-dropdown-item>
      </b-nav-item-dropdown> -->


      <button class="btn btn-outline-success" type="button" @click="LoginDetails">Login</button>
<b-navbar type="light" variant="light" >
    <b-nav-form class="d-flex">    
      <b-form-input class="mr-sm-2" placeholder="Search"></b-form-input>
      <b-button variant="outline-success" class="my-2 my-sm-0" type="submit">Search</b-button>
    </b-nav-form>
</b-navbar>

  </b-navbar-nav>
    </b-nav-form>
  </div> 
</nav>
<section class="createDocsSection py-3">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-10">
         <div class="imgWrap">
            <img src="../assets/images/MyProHelper_Logo_TwoColor_2-01.png" alt="" class="img-fluid" width="70%"/>
          </div>
          <h3>What is MyProHelper? It is a Software Solution created just for HVAC businesses.<br>
            Why Choose Us. We have many years of expertise in delivering high-quality solutions to help businesses.<br>
            This Solution was created exclusively for HVAC businesses to use. </h3>
<h4><br>               
Just a few of the HVAC business pains that are addresed by our Solution<br>
=======================================================================<br>
Keeping track of incoming calls, who called, and what help they need.<br>
Scheduling Jobs<br>
Scheduling Workers<br>
Creating Estimates and Quotes and keeping track of them.<br>
Confirm with the customer that day before, the scheduled Time.<br>
When a worker is almost finished with prior job, text or email the customer to let them know<br>
     which worker is coming, and approximately what time they should be there.<br>
Keep track of where your workers are right now, who is closest to an issue<br>
Keep track of Invoices and their payment status<br>
Accept payments even while still at the customer's house<br>
<br>
There is only one Owner/Boss, and they can't be everywhere at the same time. Allow them to work <br>
smarter by having workers save notes that the Boss/Owner can see, regarding the job they are <br>
working on, what they tried, and what still isn't working.<br>
<br>
No installation required on your computers.<br>
Free, Fast, and Friendly Customer Support. We will help you get comfortable with the product, and show you how to use new features.<br>
Our Customer Support team will Setup the settings for our program for your company, all you have to do is ask.<br>
<br>
Data will have multiple backup copies made automatically for safe-keeping.<br>
You can access the information using a Browser such as "Google Chrome, Brave, or <br>
Internet Explorer" in the office, or from your iOS or Android mobile device such as <br>
tablets, IPads, and phones.<br>
<br>
The iOS and Android devices will be operational even if there is no network connectivity at this customer's location.<br>
<br>
The iOS and Android devices will automatically update the data on the Website in the background.<br>
</h4>
      </div>
      <!-- <div class="col-md-6 text-md-end">
        <button type="button" class="btn btn-light">Template gallery <i class="fa-solid fa-sort ms-2"></i></button> |
        <button type="button" class="btn btn-light"><i class="fa-solid fa-ellipsis-vertical"></i></button>
      </div> -->
    </div>
    <!-- <div class="row">
      <div class="col-md-2">
        <div class="docsContent mx-auto">
          <div class="imgWrap">
            <img src="../assets/images/doc1.png" alt="" class="img-fluid" />
          </div>
          <div class="contentWrap py-3">
            <h5 class="mb-0">Blank</h5>
            <small></small>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-md-2">
        <div class="docsContent mx-auto">
          <div class="imgWrap">
            <img src="../assets/images/doc2.png" alt="" class="img-fluid" />
          </div>
          <div class="contentWrap py-3">
            <h5 class="mb-0">Resume</h5>
            <small>Coral</small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="docsContent mx-auto">
          <div class="imgWrap">
            <img src="../assets/images/doc3.png" alt="" class="img-fluid" />
          </div>
          <div class="contentWrap py-3">
            <h5 class="mb-0">Resume</h5>
            <small>Serif</small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="docsContent mx-auto">
          <div class="imgWrap">
            <img src="../assets/images/doc4.png" alt="" class="img-fluid" />
          </div>
          <div class="contentWrap py-3">
            <h5 class="mb-0">Letter</h5>
            <small>Spearmint</small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="docsContent mx-auto">
          <div class="imgWrap">
            <img src="../assets/images/doc5.png" alt="" class="img-fluid" />
          </div>
          <div class="contentWrap py-3">
            <h5 class="mb-0">Project proposal</h5>
            <small>Tropic</small>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="docsContent mx-auto">
          <div class="imgWrap">
            <img src="../assets/images/doc6.png" alt="" class="img-fluid" />
          </div>
          <div class="contentWrap py-3">
            <h5 class="mb-0">Brochure</h5>
            <small>Geometric</small>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    </div>
</section>
</template>
  
  <script>
   export default {
     name: 'UserLogin',
     data() {
     return {
  //     UserName:'',
  //     Password:''
     };
  },
   methods: {
    LoginDetails() {
//      window.location = "http://localhost:8080";    //works
//window.location = process.env.NODE_ENV === 'production' ? 'https://myprohelper.com/login' : 'http://localhost:8080';   
      window.location = process.env.NODE_ENV === 'production' ? '/login' : '/login';   
    },
    Terms() {
      //        alias /alta/static/terms.pdf;
      //        default_type application/pdf;
      //window.location = process.env.NODE_ENV === 'production' ? 'https://myprohelper.com/Terms' : 'http://localhost:8080/Terms';   
      window.location = process.env.NODE_ENV === 'production' ? '/Terms' : '/Terms';   
    },
    Privacy() {
          //        alias /alta/static/privacy_policy.pdf;
          //        default_type application/pdf;  
          window.location = process.env.NODE_ENV === 'production' ? '/Privacy' : '/Privacy';   
    }
  },

 
  mounted() {
    
   
    }
  }
  </script>
  
