<template>
  <UserLogin msg="Welcome to Your Vue.js App"/>
 
</template>

<script>
import UserLogin from './Auth/UserLogin.vue'

export default {
  name: 'App',
  components: {
    UserLogin
  }
}
</script>

<style lang="scss">
// Import Main styles for this application

@import "assets/scss/style";
@import "node_modules/bootstrap/scss/bootstrap";
// Then import Bootstrap and BootstrapVue SCSS files (order is important)
// @import 'node_modules/bootstrap/scss/bootstrap.scss';
// @import 'node_modules/bootstrap-vue/src/index.scss';
</style>